import React, { FC } from "react";

import Icon from "components/Shared/Icons/Icon/Icon";
import { genClasses } from "utils/textHelpers";
import colors from "constants/colors";

interface IProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  withMarginLeft?: boolean;
  onClear?: () => void;
  style?: React.CSSProperties;
}

const SearchBar: FC<IProps> = ({ value, onChange, withMarginLeft, onClear, style }) => {
  return (
    <div
      className={genClasses("search-bar", {
        "search-bar--with-margin-left": withMarginLeft
      })}
      style={{ position: "relative", ...style }}
    >
      <input
        value={value}
        type="text"
        className="search-bar-input"
        placeholder="type to search ..."
        onChange={onChange}
      />
      <div className="search-bar-icon">
        {value ? (
          <Icon icon="close" onClick={onClear} style={{ cursor: "pointer" }} color={colors.black} />
        ) : (
          <Icon icon="search" color={colors.midGrey} />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
