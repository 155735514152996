import React from "react";

import ImprovementSection from "./ImprovementSection/ImprovementSection";
import CourseworkSection from "./CourseworkSection/CourseworkSection";
import TestScoresSection from "./TestScoresSection/TestScoresSection";
import ParticipationSection from "./Participation/Participation";
import { useSelector } from "react-redux";
import { makeSelectFetchSpinner } from "Store/global/selectors";
import { makeSelectSummary } from "Store/dashboard/selectors";
import Spinner from "components/Shared/Spinner/Spinner";

const SummarySection = () => {
  const isLoading = useSelector(makeSelectFetchSpinner);
  const summary = useSelector(makeSelectSummary);

  return (
    <div>
      {isLoading || !summary ? (
        <Spinner showSpinner={isLoading} />
      ) : (
        <div className="summary">
          <ImprovementSection subjects={summary.top_subject_scores} />
          <div className="summary-row">
            <CourseworkSection coursework={summary.coursework ? summary.coursework : undefined} />
            <TestScoresSection summary={summary} />
          </div>
          <ParticipationSection
            participation={summary.participation ? summary.participation : undefined}
          />
        </div>
      )}
    </div>
  );
};

export default SummarySection;
