import React from "react";
import WhatsNew from "components/Shared/WhatNew/WhatsNew";

const WhatsNewTab = () => {
  return (
    <div className="dashboard-news-tab">
      <WhatsNew />
    </div>
  );
};

export default WhatsNewTab;
