import React, { FC, useEffect, useState } from "react";

import TotalScore from "components/Shared/Scores/TotalScore/TotalScore";
import SectionScores from "components/Shared/Scores/SectionScores/SectionScores";
import { useSelector } from "react-redux";
import {
  calculateTestScoresSATdelta,
  calculateTestScoresACTdelta
} from "utils/calculateTestScores";
import { makeSelectActiveCourse } from "Store/user/selectors";

interface IProps {
  subjects: SubjectScoreSummary;
}
const courseMapping = {
  SAT: (subjectScores: any[]) => calculateTestScoresSATdelta(subjectScores),
  ACT: (subjectScores: any[]) => calculateTestScoresACTdelta(subjectScores),
  dSAT: (subjectScores: any[]) => calculateTestScoresSATdelta(subjectScores)
};

const ImprovementSection: FC<IProps> = ({ subjects }) => {
  const activeCourse = useSelector(makeSelectActiveCourse);
  const [scores, setScores] = useState([]);

  const [totalScore, setTotalScore] = useState<number>(0);
  const [delta, setDelta] = useState<number>(0);

  useEffect(() => {
    if (subjects) {
      const scores = mappedBestScoreSubjects();
      const ttlScore = scores.reduce((acc, score) => acc + score.current_score, 0);
      setScores(scores);
      setTotalScore(activeCourse.course_type === "ACT" ? ttlScore / scores.length : ttlScore);
      setDelta(courseMapping[activeCourse.course_type](scores));
    }
  }, [subjects]);

  const mappedBestScoreSubjects = () => {
    return Object.keys(subjects)?.map((key) => subjects[key]);
  };

  return (
    <div className="card-block" style={{ width: "100%" }}>
      <h3 className="summary-label">Scores Summary</h3>
      <div className="summary-content summary-dashboard">
        <TotalScore
          header="Your Best Score"
          annotation="from all Tests"
          value={totalScore}
          min={activeCourse.stats.minTotal}
          max={activeCourse.stats.total}
          delta={delta}
          showGraph
        />
        <SectionScores
          subjects={scores}
          courseSubjects={activeCourse.stats}
          big
          activeCourse={activeCourse}
        />
      </div>
    </div>
  );
};

export default ImprovementSection;
