import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { makeSelectActiveCourse } from "Store/user/selectors";
import {makeSelectSummary} from "../../../../Store/dashboard/selectors";

interface IProps {
  score: SubjectScoreSummary;
  testType: any;
  baselines: Baseline[];
}

const SubjectScore: FC<IProps> = ({ score, testType, baselines }) => {
  const [options, setOptions] = useState({});
  const activeCourse = useSelector(makeSelectActiveCourse);
  const summary = useSelector(makeSelectSummary);

  const getBaselineScore = (name) => {
    if (!activeCourse.subjects) return [];

    const courseBaselines = activeCourse.subjects?.map((s) => {
      const b = baselines?.find((b) => b.subject_id === s.id);
      return { ...b, name: s.name };
    });
    const baseline = courseBaselines?.find((b) => b.name?.split(" ")[0].toLowerCase() === name);
    if (!baseline || !Boolean(baseline.baseline)) return [];
    return [baseline?.baseline];
  };

  useEffect(() => {
    if (activeCourse) {
      setOptions({
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: activeCourse.course_type === "ACT" ? 36 : 800,
                stepSize: activeCourse.course_type === "ACT" ? 6 : 200
              }
            }
          ]
        },
        layout: {
          padding: {
            top: 10
          }
        }
      });
    }
  }, [activeCourse]);

  const getFormattedDateLabelArray = (data) => {
    return data?.map((item) => moment(item).format("MMM Do YY"));
  };

  const formatGraphData = () => {
    const {
      Reading: reading = [],
      Math: math = [],
      English: english = [],
      "Writing and Language": writing = [],
      "Reading & Writing": reading_writing = [],
      Science: science = []
    } = score;

    const readingTmp = reading.length > 0 ? reading?.map((score) => score.current_score) :  reading_writing?.map((score) => score.current_score);
    const readingDate = reading.length > 0 ? reading?.map((score) => score.date) :  reading_writing?.map((score) => score.date);
    const formattedDateArray = getFormattedDateLabelArray(readingDate);

    const readingScoreData = [
      ...getBaselineScore("reading"),
      ...readingTmp
    ];

    const mathScoreData = [
      ...getBaselineScore("math"),
      ...math?.map((score) => score.current_score)
    ];

    let datasets = [];
    if (testType === "SAT" || testType === "dSAT") {
      const writingAndLanguageScoreData = [
        ...getBaselineScore("writing"),
        ...writing?.map((score) => score.current_score)
      ];
      datasets = [readingScoreData, mathScoreData, writingAndLanguageScoreData].filter(function (el) {
        return el.length > 0;
      });
    } else if (testType === "ACT") {
      const englishData = [
        ...getBaselineScore("english"),
        ...english?.map((score) => score.current_score)
      ];
      const scienceData = [
        ...getBaselineScore("science"),
        ...science?.map((score) => score.current_score)
      ];
      datasets = [scienceData, englishData, mathScoreData, readingScoreData];
    }

    const labels = Object.keys(summary.top_subject_scores);

    const colors =
      testType === "ACT"
        ? ["#35a6af", "#35a6af", "#4785f4", "#55b24b"]
        : ["#35a6af", "#4785f4", "#55b24b"];

    return {
      labels: formattedDateArray,
      datasets: datasets?.map((score, index) => ({
        label: labels[index],
        fill: false,
        lineTension: 0.1,
        backgroundColor: colors[index],
        borderColor: colors[index],
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colors[index],
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: score
      }))
    };
  };

  return <Line data={formatGraphData()} options={options} />;
};

export default SubjectScore;
