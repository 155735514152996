import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import TodoSection from "./TodoSection/TodoSection";

import { handleGetTodo } from "Store/dashboard/actions";
import { setReturnPage } from "Store/lessons/actions";

import { makeSelectTodoList } from "Store/dashboard/selectors";
import { makeSelectFetchSpinner } from "Store/global/selectors";
import ListWrapper, { List } from "components/Shared/ListWrapper/ListWrapper";
import { makeSelectActiveUser } from "Store/user/selectors";
import {makeSelectStudentActiveTest} from "../../../Store/tests/selectors";

const TodoList = () => {
  const dispatch = useDispatch();
  const todo = useSelector(makeSelectTodoList);
  const showSpinner = useSelector(makeSelectFetchSpinner);
  const activeUser = useSelector(makeSelectActiveUser);

  useEffect(() => {
    if (activeUser) {
      dispatch(handleGetTodo());
    }
    dispatch(setReturnPage("dashboard"));
  }, [activeUser]);

  return (
    <ListWrapper showSpinner={showSpinner} itemsCount={Object.keys(todo).length}>
      <List>
        <ul className="to-do-list-general">
          {Object.keys(todo)?.map((key, index) => (
            <TodoSection todo={todo[key]} key={index} section={key} />
          ))}
        </ul>
      </List>
    </ListWrapper>
  );
};

export default TodoList;
