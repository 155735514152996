import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";

import TaskListItem from "components/Shared/TaskList/TaskListItem/TaskListItem";
import Button from "components/Shared/Button/Button";

import {
  handleOpenDetailTestView, handleStartBlueBookTest,
  handleStartStudentTest,
  handleStartTribyteTest, setStudentActiveTest
} from "Store/tests/actions";

import { determineIfLesson, determineIfWorksheet } from "utils/determineItemType";
import { ICON_COLORS } from "constants/colors";
import Router, {useRouter} from "next/router";
import DigitalTestModal from "components/Shared/Modals/DigitalTestModal/DigitalTestModal";
import { makeSelectStudentActiveTest } from "Store/tests/selectors";
import BlueBookTestModal from "../../../Shared/Modals/BlueBookTestModal/BlueBookTestModal";

interface IProps {
  item: Test | StudentLesson | Worksheet;
  today?: boolean;
}

const TodoRow: FC<IProps> = ({ item, today }) => {
  const { id, status, due_date: dueDate, name, sections = [], type } = item;
  const [isDigitalTestModal, setIsDigitalTestModal] = useState<boolean>(false);
  const [overdueStatus, setOverdueStatus] = useState(false);
  const [activeTest, setActiveTest] = useState<Test>(null);
  const [done, setDone] = useState<boolean>(false);
  const studentActiveTest = useSelector(makeSelectStudentActiveTest);
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    if (studentActiveTest?.status == 'COMPLETED' && done) {
      onCloseDigitalTest();
    }
  }, [studentActiveTest]);

  useEffect(() => {
    const today = moment().local();
    const due = moment(item.due_date).local();
    const overDueStatus = moment(today).isAfter(due, "day");
    setOverdueStatus(overDueStatus);
  }, []);

  const onDetermineRowClick = (event) => {
    const lessonType = determineIfLesson(item) ? item.lesson_type : null;
    event.preventDefault();
    if (sections.length) {
      const section = (sections as Section[])?.find((section) => section.status !== "COMPLETE");
      onLessonClick(section);
    } else if (type === "test") {
      if (item.test_type == 'TRIBYTE' || item.test_type == 'BB') {
        setActiveTest(item as Test);
      } else {
        onTestClick();
      }
    } else if (lessonType === "Drill") {
      onLessonClick();
    } else if (lessonType === "Reading") {
      onLessonClick();
    }
  };
  const unavailable = () => {
    const date = moment().utc();
    return moment(item.assignment_date).utc().isAfter(date);
  };

  const onLessonClick = (section?: Section) => {
    if (!determineIfLesson(item)) return false;
    Router.push(
      `/lessons/[lessonId]${section ? "/section/[sectionId]" : ""}`,
      `/lessons/${item.id}${section ? `/section/${section.id}` : ""}`,
      {
        shallow: true
      }
    );
  };

  const onWorksheetClick = (worksheetId) => {
    Router.push("/worksheets/[worksheetId]", `/worksheets/${worksheetId}`, {
      shallow: true
    });
  };

  const onTestClick = () => {
    item.status === "COMPLETED"
      ? dispatch(handleOpenDetailTestView(id))
      : dispatch(handleStartStudentTest(item as Partial<Test>));
  };

  const onStartDigitalTest = () => {
    setIsDigitalTestModal(true);
    if (item.test_type == 'TRIBYTE') {
      dispatch(handleStartTribyteTest(item as Partial<Test>));
    } else if (item.test_type == 'BB') {
      dispatch(handleStartBlueBookTest(item as Partial<Test>));
    }
  };

  const checkChallengeCompletedStatus = (section) => {
    if (item.hasOwnProperty("sections") && item?.sections?.length < 2) return true;
    if (section.type === "practice") {
      const challengeSection = (item.sections as Section[])?.find(
        (sect) => sect.type === "challenge"
      );
      return challengeSection && challengeSection.status === "COMPLETED";
    }
    return true;
  };

  const getButtonName = (status) => {
    let title;
    switch (status) {
      case "ASSIGNED":
        title = unavailable()
          ? `Available <div style="font-size: 12px; font-weight: 300">${moment(
              item?.assignment_date
            ).format("MM/DD/YYYY HH:mm")}</div>`
          : "Click to begin";
        break;
      case "COMPLETED":
        title =
          determineIfLesson(item) && item.lesson_type === "Reading"
            ? "Click to read again"
            : "Click to review";
        break;
      case "STARTED":
        title = "Click to continue";
        break;
      default:
        title = "Click to begin";
        break;
    }
    return title;
  };
  const getIconColor = (status, section?) => {
    if (unavailable() || (section && !checkChallengeCompletedStatus(section)))
      return ICON_COLORS.unavailable;
    switch (status) {
      case "COMPLETED":
        return ICON_COLORS.completed;
      case "STARTED":
      case "ASSIGNED":
        return ICON_COLORS.overdue;
    }
  };

  const mapSectionStatus = () => {
    if (sections.length) {
      return sections?.map((section) => (
        <Button
          key={section.id}
          name={
            !checkChallengeCompletedStatus(section)
              ? `Finish previous </br> <div class="button-subtitle">section first</div>`
              : `${getButtonName(section.status)} ${
                  !unavailable()
                    ? `<br/><div class="button-subtitle">Page: ${
                        determineIfLesson(item)
                          ? section.type === "practice"
                            ? item.practice_page
                            : item.challenge_page
                          : ""
                      }</div>`
                    : ""
                }`
          }
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onLessonClick(section);
          }}
          disabled={!checkChallengeCompletedStatus(section) || unavailable()}
          type={section.status === "COMPLETED" ? "complete" : "primary-reverse"}
          fixedWidth
          icon={section.type === "challenge" ? "cu-challenge" : "cu-practice"}
          iconStyle={{
            color: getIconColor(section.status, section),
            width: section.type === "challenge" ? 24 : 18,
            height: section.type === "challenge" ? 24 : 18
          }}
        />
      ));
    } else if (type === "test") {
      if (item.test_type == 'TRIBYTE' || item.test_type == 'BB') {
        return (
            <Button
                name={getButtonName(status)}
                onClick={onStartDigitalTest}
                type={status === "COMPLETED" ? "complete" : "primary-reverse"}
                disabled={unavailable()}
                fixedWidth
            />
        );
      } else {
        return (
          <Link href="/tests/[testId]" as={`/tests/${id}`}>
            <Button
              name={getButtonName(status)}
              onClick={(e) => {
                e.preventDefault();
                item.status === "COMPLETED"
                  ? dispatch(handleOpenDetailTestView(id))
                  : dispatch(handleStartStudentTest(item as Partial<Test>));
              }}
              type={status === "COMPLETED" ? "complete" : "primary-reverse"}
              fixedWidth
              disabled={unavailable()}
            />
          </Link>
        );
      }
    } else if (type === "worksheet") {
      return (
        <Link href={"/worksheets/[worksheetId]"} as={`/worksheets/${id}`}>
          <Button
            name={getButtonName(status)}
            onClick={(e) => {
              e.preventDefault();
              onWorksheetClick(id);
            }}
            fixedWidth
            type={status === "COMPLETED" ? "complete" : "primary-reverse"}
            disabled={unavailable()}
          />
        </Link>
      );
    }
    const lessonType = determineIfLesson(item) ? item.lesson_type : null;

    if (lessonType === "Drill") {
      return (
        <Link href={"/lessons/[lessonId]"} as={`/lessons/${id}/`}>
          <Button
            name={`${getButtonName(status)}${
              !unavailable()
                ? `<br/><div class="button-subtitle">Page: ${
                    determineIfLesson(item) && item.drill_page
                  }</div>`
                : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onLessonClick();
            }}
            type={status === "COMPLETED" ? "complete" : "primary-reverse"}
            fixedWidth
            disabled={unavailable()}
            icon="cu-drill"
            iconStyle={{ color: getIconColor(status) }}
          />
        </Link>
      );
    } else if (lessonType === "Assigned Reading") {
      return (
        <Link href="/lessons/[lessonId]" as={`/lessons/${id}`}>
          <Button
            name={`${getButtonName(status)}${
              !unavailable()
                ? `<br/><div class="button-subtitle">Page: ${
                    determineIfLesson(item) && item.starting_page
                  }</div>`
                : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onLessonClick();
            }}
            type={status === "COMPLETED" ? "complete" : "primary-reverse"}
            fixedWidth
            disabled={unavailable()}
            icon="cu-reading"
            iconStyle={{ color: getIconColor(status) }}
          />
        </Link>
      );
    }
    return null;
  };
  const returnTitle = () => {
    return name || (determineIfWorksheet(item) && item.title);
  };

  function onCloseDigitalTest() {
    setIsDigitalTestModal(false);
    router.push("/tests")
  }

  function onDoneDigitalTest() {
    setDone(true);
  }

  return (
    <>
      <TaskListItem
        item={item}
        onClick={onDetermineRowClick}
        title={returnTitle()}
        status={overdueStatus ? "OVERDUE" : status.toString()}
        date={dueDate}
        sections={mapSectionStatus()}
        today={today}
      />
      {isDigitalTestModal && studentActiveTest && (studentActiveTest.test_type == 'TRIBYTE' || studentActiveTest.test?.type == 'TRIBYTE' ) && (
          <DigitalTestModal
              onClose={onCloseDigitalTest}
              url={studentActiveTest?.url}
              test={studentActiveTest}
          />
      )}
      {isDigitalTestModal && studentActiveTest && (studentActiveTest.test_type == 'BB' || studentActiveTest.test?.type == 'BB' ) && (
          <BlueBookTestModal
              onClose={onCloseDigitalTest}
              onDone={onDoneDigitalTest}
              test={studentActiveTest}
          />
      )}
    </>
  );
};

export default TodoRow;
