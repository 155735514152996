import React, { FC } from "react";

import TodoRow from "components/dashboard/TodoList/TodoRow/TodoRow";
import { genClasses } from "utils/textHelpers";

interface IProps {
  todo: any[];
  section: string;
}

const SECTION_NAME = {
  any_time: "No Due Date",
  overdue: "Overdue",
  this_week: "Due This Week",
  next_week: "Due Next Week",
  tomorrow: "Due Tomorrow",
  today: "Due Today",
  after_next_week: "Due After Next Week"
};
const TodoSection: FC<IProps> = ({ todo, section }) => {
  return (
    <li className={`todo-list-row period_${section === "any_time" ? "no_due_date" : section}`}>
      <div className="title-block">
        <strong className="do-title">
          {SECTION_NAME[section]}
          <span
            className={genClasses("badge-circle", {
              "badge-circle--pill": todo.length > 99
            })}
          >
            {todo.length}
          </span>
        </strong>
      </div>
      {todo?.map((item) => (
        <TodoRow key={item.id} item={item} today={section === "today"} />
      ))}
    </li>
  );
};
export default TodoSection;
