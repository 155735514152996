export const determineIfLesson = (
  toBeDetermined: StudentLesson | Worksheet | Test
): toBeDetermined is StudentLesson => {
  return !!(toBeDetermined as StudentLesson).type;
};

export const determineIfWorksheet = (
  toBeDetermined: StudentLesson | Worksheet | Test
): toBeDetermined is Worksheet => {
  return !!(toBeDetermined as Worksheet).type;
};

export const determineIfTest = (
  toBeDetermined: StudentLesson | Worksheet | Test
): toBeDetermined is Test => {
  return !!(toBeDetermined as Test).type;
};
