import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import { Line, Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { makeSelectActiveCourse } from "Store/user/selectors";

interface IProps {
  score: CompositeScore[];
  isStudentActive: boolean;
  baselines: Baseline[];
}

const CompositeScore: FC<IProps> = ({ score, isStudentActive, baselines }) => {
  const [options, setOptions] = useState({});
  const activeCourse = useSelector(makeSelectActiveCourse);

  const getFormattedDateLabelArray = (data) => {
    return data?.map((item) => moment(item.date).format("MMM Do YY"));
  };

  useEffect(() => {
    if (activeCourse) {
      setOptions({
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: activeCourse.course_type === "ACT" ? 36 : 1600,
                stepSize: activeCourse.course_type === "ACT" ? 6 : 200
              }
            }
          ]
        },
        layout: {
          padding: {
            top: 10
          }
        }
      });
    }
  }, [activeCourse]);

  const getSubjectBaselineScore = () => {
    if (!baselines || baselines?.length === 0 || activeCourse?.subjects.length === 0) return [];
    const courseBaselines = activeCourse.subjects?.map((subject) =>
      baselines?.find((baseline) => baseline.subject_id === subject.id)
    );
    if (courseBaselines.some((baseline) => !baseline.baseline)) {
      return [];
    } else {
      const courseFactor = activeCourse.course_type === "ACT" ? 4 : 1;
      const x =
        courseBaselines?.reduce((acc, baseline) => acc + baseline.baseline, 0) / courseFactor;
      return [Math.round(x)];
    }
  };

  const formatBarGraphData = () => {
    const formattedDateArray = getFormattedDateLabelArray(score);
    let scoreData = [];
    if (isStudentActive) scoreData = score?.map((item) => item.score);

    return {
      labels: formattedDateArray,
      datasets: [
        {
          label: "Composite Score",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "#00BBFF",
          barThickness: 30,
          maxBarThickness: 30,
          borderColor: "#00BBFF",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          hoverBackgroundColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: scoreData
        }
      ]
    };
  };

  const formatGraphData = () => {
    const formattedDateArray = getFormattedDateLabelArray(score);
    const scoreData = score?.map((item) => item.score);
    return {
      labels: formattedDateArray,
      datasets: [
        {
          label: "Composite Score",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "#00BBFF",
          borderColor: "#00BBFF",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          hoverBackgroundColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [...getSubjectBaselineScore(), ...scoreData]
        }
      ]
    };
  };

  return score?.length > 1 ? (
    <Line data={formatGraphData()} options={options} />
  ) : (
    <Bar data={formatBarGraphData()} options={options} />
  );
};

export default CompositeScore;
