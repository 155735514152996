import React, { FC } from "react";
import colors from "constants/colors";

interface IProps {
  progress: number;
  className?: string;
  fillColor?: string;
}

const StatusBar: FC<IProps> = ({ progress, className, fillColor }) => {
  return (
    <div className={`status-bar-container ${className || ""}`}>
      <div
        className="status-bar-progress"
        style={{
          width: `${progress.toFixed(0)}%`,
          background: fillColor || colors.instructorGreen
        }}
      />
    </div>
  );
};

export default StatusBar;
