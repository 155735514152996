import React, { FC, useState, useEffect } from "react";
import NewsVideoPlayer from "components/Shared/NewsVideoPlayer/NewsVideoPlayer";
import Icon from "components/Shared/Icons/Icon/Icon";
import { useSelector } from "react-redux";
import { makeSelectActiveUser, makeSelectLocation } from "Store/user/selectors";
import colors, { setContrast } from "constants/colors";
import FullscreenImage from "./FullscreenImage/FullscreenImage";
import NEWS from "constants/localization/news";
import { getSlidesToDisplay } from "utils/newsHelpers";

interface IProps {
  fromModal?: boolean;
  onEnableCloseButton?: () => void;
}

const WhatsNew: FC<IProps> = ({ fromModal, onEnableCloseButton }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const location = useSelector(makeSelectLocation);
  const [textColor, setTextColor] = useState("white");
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [news, setNews] = useState<News[]>(NEWS);
  const activeUser = useSelector(makeSelectActiveUser);

  const handleFullscreenImage = (image: string) => {
    setFullscreenImage(image);
  };

  useEffect(() => {
    if (activeUser && activeUser.last_version_seen && fromModal) {
      setNews(getSlidesToDisplay(activeUser.last_version_seen));
    } else if (!fromModal) {
      setNews(NEWS);
    }
  }, [activeUser]);

  const getMediaElement = (item) => {
    if (item.hasOwnProperty("video")) {
      return <NewsVideoPlayer videoUrl={item.video} />;
    } else if (item.hasOwnProperty("image")) {
      return (
        <img
          src={`/static/images/${item.image}`}
          alt={item.title}
          onClick={() => handleFullscreenImage(item.image)}
        />
      );
    }
  };

  const prevSlide = () => {
    setActiveSlide(activeSlide === 0 ? news.length - 1 : activeSlide - 1);
  };
  const nextSlide = () => {
    if (activeSlide + 2 >= news.length && fromModal) {
      onEnableCloseButton();
    }
    setActiveSlide(activeSlide + 1 === news.length ? 0 : activeSlide + 1);
  };

  useEffect(() => {
    if (location?.branding?.color1) {
      setTextColor(setContrast(location?.branding?.color1));
    }
  }, []);

  return news.length > 0 ? (
    <div className="carousel-container">
      {fullscreenImage && (
        <FullscreenImage image={fullscreenImage} onClose={() => setFullscreenImage(null)} />
      )}
      <div
        className="slide-title"
        style={{
          background: fromModal
            ? `linear-gradient(45deg, ${location?.branding.color1} 0%,${location?.branding.color2} 100% )`
            : "none",
          color: fromModal ? textColor : "black"
        }}
      >
        {news && news.length > 0 && news[activeSlide] && news[activeSlide].title}
      </div>
      <div className="slides-container">
        {activeSlide !== 0 && (
          <div className="slides-left-arrow" onClick={prevSlide}>
            <Icon icon="chevron-left" />
          </div>
        )}
        <div className="slides">
          {news.length > 0
            ? news?.map((item, index) => (
                <div className={`slide ${index === activeSlide ? "active" : ""}`} key={index}>
                  {index === activeSlide && (
                    <>
                      <div className="slide-media">{getMediaElement(item)}</div>
                      <div
                        className="slide-description"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </>
                  )}
                </div>
              ))
            : null}
        </div>
        {activeSlide + 1 !== news.length && (
          <div className="slides-right-arrow" onClick={nextSlide}>
            <Icon icon="chevron-right" />
          </div>
        )}
      </div>
      <div className="carousel-dots">
        {news?.map((item, index) => (
          <span key={index} className={`carousel-dot ${activeSlide === index ? "active" : ""}`} />
        ))}
      </div>
    </div>
  ) : (
    <h1 style={{ width: "100%", textAlign: "center", color: colors.grey }}>No News</h1>
  );
};

export default WhatsNew;
