import React, { FC } from "react";
import moment from "moment";

import Icon from "components/Shared/Icons/Icon/Icon";
import colors from "constants/colors";

interface IProps {
  participation: Participation;
}

const ParticipationSection: FC<IProps> = ({
  participation: {
    last_log_in,
    on_time_percentage,
    problems_reviewed,
    problems_to_review,
    questions_answered,
    video_watched
  }
}) => {
  const formatTimeFromMins = (totalSeconds) => {
    return (
      <>
        <b className="participation-time">
          {moment
            .utc(totalSeconds * 1000)
            .format("H:mm")
            .replace(":", "h ")}
          min
        </b>
      </>
    );
  };

  return (
    <div className="card-block p-0 participation">
      <h3 className="summary-label">Participation</h3>
      <div className="participation-stats-container">
        <div className="participation-stats">
          <div className="participation-icon">
            <Icon icon="pen" style={{ width: "42px", height: "42px" }} color={colors.studentBlue} />
          </div>
          <p className="participation-header">Questions Answered</p>
          <div className="participation-info">{questions_answered}</div>
        </div>
        <div className="participation-stats">
          <div className="participation-icon">
            <Icon
              icon="bells"
              style={{ width: "42px", height: "42px" }}
              color={colors.studentBlue}
            />
          </div>
          <p className="participation-header">On-Time Percentage</p>
          <div className="participation-info">{on_time_percentage}%</div>
        </div>
        <div className="participation-stats">
          <div className="participation-icon">
            <Icon
              icon="play-circle"
              style={{ width: "42px", height: "42px" }}
              color={colors.studentBlue}
            />
          </div>
          <p className="participation-header">Video Watched</p>
          <div className="participation-info">
            {video_watched ? formatTimeFromMins(video_watched) : "-"}
          </div>
        </div>
        <div className="participation-stats">
          <div className="participation-icon">
            <Icon
              icon="pennant"
              style={{ width: "42px", height: "42px" }}
              color={colors.studentBlue}
            />
          </div>
          <p className="participation-header" style={{ marginBottom: 0 }}>
            Problems
          </p>
          <div className="participation-header">
            <p style={{ marginBottom: 0 }}>
              to Review: <span className="participation-orange">{problems_to_review}</span>
            </p>
            <p style={{ marginTop: 0 }}>
              Reviewed: <span className="participation-annotation">{problems_reviewed}</span>
            </p>
          </div>
        </div>
        <div className="participation-stats">
          <div className="participation-icon">
            <Icon
              icon="sign-in"
              style={{ width: "42px", height: "42px" }}
              color={colors.studentBlue}
            />
          </div>
          <p className="participation-header">Last Log In</p>
          <div className="participation-info login-time">
            <div>{last_log_in ? moment(last_log_in).format("MM/DD/YYYY") : "-"}</div>
            <div>
              {last_log_in ? moment(last_log_in).format("hh:mm") : "-"}
              {last_log_in ? moment(last_log_in).format("A") : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipationSection;
