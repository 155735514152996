import React, { FC, useRef, useState } from "react";
import colors from "constants/colors";
import Icon from "components/Shared/Icons/Icon/Icon";

interface IProps {
  videoUrl: string;
}

const NewsVideoPlayer: FC<IProps> = ({ videoUrl }) => {
  const videoRef = useRef(null);
  const videoStateRef = useRef("PAUSED");
  const [volume, setVolume] = useState(0.5);
  const [showVolume, setShowVolume] = useState(false);
  const [watchTime, setWatchTime] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState(0);

  const videoLoaded = () => {
    setDuration(Math.round(videoRef.current.duration));
    videoRef.current.currentTime = 0;
    videoStateRef.current = "PAUSED";
  };

  const calcTime = (time) => {
    const min = Math.floor(Math.floor(time) / 60);
    const sec = Math.round(time) % 60;

    const formattedMin = min < 10 ? `0${min}` : String(min);
    const formattedSec = sec < 10 ? `0${sec}` : String(sec);

    return `${formattedMin} : ${formattedSec}`;
  };

  const playVideo = () => {
    // videoRef.current.currentTime = parseFloat(currentTime.toString());
    videoRef.current.play();
    videoStateRef.current = "PLAYS";
    videoRef.current.volume = 0.5;
  };

  const pauseVideo = () => {
    videoRef.current.pause();
    videoStateRef.current = "PAUSED";
  };

  const showVolumeBar = () => {
    setShowVolume(true);
  };

  const hideVolumeBar = () => {
    setShowVolume(false);
  };

  const changeVolume = (e) => {
    videoRef.current.volume = e.currentTarget.value;
    setVolume(e.currentTarget.value);
  };

  const handleMute = () => {
    setIsMuted((prev) => !prev);
  };

  const moveSeek = () => {
    setWatchTime(videoRef.current.currentTime);
  };

  return (
    <div className="news-video-player">
      <div id="video-player">
        <video
          id="video-source"
          className="in-modal embed-responsive-item"
          ref={videoRef}
          src={videoUrl}
          preload="metadata"
          playsInline
          onLoadedData={videoLoaded}
          onTimeUpdate={moveSeek}
          // onEnded={handleVideoEnded}
          muted={isMuted}
        />
      </div>
      <div id="video-control">
        {videoStateRef.current === "PAUSED" && (
          <button id="play-button" name="play" onClick={playVideo}>
            <Icon icon="play" color={colors.white} />
          </button>
        )}
        {videoStateRef.current === "PLAYS" && (
          <button id="pause-button" onClick={pauseVideo}>
            <Icon icon="pause" color={colors.white} />
          </button>
        )}

        <div id="seek-bar">
          <label id="time-start-label">
            {calcTime(watchTime)} / {calcTime(duration)}
          </label>
        </div>
        <div id="volume-bar-container" onMouseOver={showVolumeBar}>
          {showVolume && (
            <input
              id="volume-bar"
              name="volume"
              value={volume}
              type="range"
              min={0}
              max={1}
              step="0.01"
              onChange={(e) => changeVolume(e)}
              onMouseUp={() => setShowVolume(false)}
              onMouseOut={hideVolumeBar}
            />
          )}
        </div>
        <button id="volume-button" onMouseOver={showVolumeBar} onClick={handleMute}>
          <Icon icon={isMuted || volume === 0 ? "volume-off" : "volume-on"} color={colors.white} />
        </button>
      </div>
    </div>
  );
};

export default NewsVideoPlayer;
