import React, { FC, useState } from "react";

import CompositeScore from "components/dashboard/Summary/CompositeScore/CompositeScore";
import SubjectScore from "components/dashboard/Summary/SubjectScore/SubjectScore";
import NavTabs from "components/Shared/Navbar/NavTabs";
import { useSelector } from "react-redux";
import { makeSelectActiveCourse, makeSelectActiveUser } from "Store/user/selectors";

interface IProps {
  summary: StudentSummary;
}

const TABS = [
  {
    label: "Composite Score",
    id: "composite"
  },
  {
    label: "Subject Scores",
    id: "subject"
  }
];

const TestScoresSection: FC<IProps> = ({ summary }) => {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const activeCourse = useSelector(makeSelectActiveCourse);
  const student = useSelector(makeSelectActiveUser);

  const isStudentActive = () => activeCourse && activeCourse.active;

  return (
    <div className="card-block test-scores">
      <h3 className="summary-label">Test Scores</h3>
      <NavTabs tabs={TABS} activeTab={activeTab} onTabChange={setActiveTab} />
      <div
        className="card-main card card-chart summary-content test-scores-content"
        style={{
          display: "block"
        }}
      >
        <div className="card-content">
          <div className="tabs-container tabs-charts-container">
            <div className="tab-content active" style={{ display: "block", padding: "20px" }}>
              {activeTab.id === "composite" ? (
                <CompositeScore
                  score={summary.composite_scores}
                  isStudentActive={isStudentActive()}
                  baselines={student?.baseline}
                />
              ) : (
                <SubjectScore
                  score={summary.subject_scores}
                  testType={summary.test_type}
                  baselines={student?.baseline}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestScoresSection;
