import React, { FC } from "react";

import ToolTip from "components/Shared/ToolTip/ToolTip";
import Icon from "components/Shared/Icons/Icon/Icon";
import colors from "constants/colors";

interface IProps {
  info: string | React.ReactNode;
  style?: React.CSSProperties;
  onLeft?: boolean;
  toolTipStyle?: React.CSSProperties;
  white?: boolean;
  left?: boolean;
}

const InfoIcon: FC<IProps> = ({ info, style, onLeft, toolTipStyle, white, left }) => {
  return (
    <div className="info-icon-container" style={style}>
      <ToolTip tips={info} onLeft={onLeft} style={toolTipStyle} white={white} left={left}>
        <Icon icon="info-circle" color={colors.black} />
      </ToolTip>
    </div>
  );
};

export default InfoIcon;
