import React, { FC } from "react";

import Skill from "components/Shared/Skill/Skill";
import Icon from "components/Shared/Icons/Icon/Icon";

interface IProps {
  coursework: Coursework;
}

const CourseworkSection: FC<IProps> = ({ coursework }) => {
  const { due_soon, due_today, overdue, completed } = coursework;

  const getSum = () =>
    Object.keys(coursework).reduce((acc, key) => acc + coursework[key], 0) - coursework.completed;

  return (
    <div className="card-block course-work">
      <h3 className="summary-label">Coursework</h3>
      <div className="card-main card summary-content course-work-content">
        <div className="course-work-icon">
          <div className="course-work-counter">{overdue}</div>
          <Icon icon="clipboard-list-check" style={{ fontSize: 64 }} />
          To Do Tasks
        </div>
        <div className="course-work-items">
          <div className="course-work-status-bar">
            <Skill
              header="Tasks"
              progress={completed > 0 ? (completed / getSum()) * 100 : 0}
              description={`${completed} of ${getSum()}`}
              id="skills"
            />
          </div>
          <div className="course-work-item-container">
            <div className="course-work-item">Completed</div>
            <div className="course-work-item-rect course-work-item-rect--completed">
              {completed}
            </div>
          </div>
          <div className="course-work-item-container">
            Overdue
            <div className="course-work-item-rect course-work-item-rect--overdue">{overdue}</div>
          </div>
          <div className="course-work-item-container">
            Due Today
            <div className="course-work-item-rect course-work-item-rect--today">{due_today}</div>
          </div>
          <div className="course-work-item-container">
            Due Soon
            <div className="course-work-item-rect course-work-item-rect--soon">{due_soon}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseworkSection;
