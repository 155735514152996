import React from "react";
import { useSelector } from "react-redux";

import StrengthsAndWeaknesses from "components/dashboard/Weaknesses/StrengthsAndWeaknesses";

import { makeSelectSummary } from "Store/dashboard/selectors";

const DashboardWeaknesses = () => {
  const studentSummary = useSelector(makeSelectSummary);
  const getTotalScore = (): number => {
    const stats = studentSummary.strengths_weaknesses.reduce(
      (acc, c) => ({
        correct: acc.correct + c.score.correct,
        total: acc.total + c.score.total
      }),
      {
        correct: 0,
        total: 0
      }
    );
    return (stats.correct * 100) / stats.total;
  };

  return (
    <StrengthsAndWeaknesses
      totalScore={getTotalScore()}
      categories={studentSummary.strengths_weaknesses}
      subjects={studentSummary.strengths_weaknesses}
      percent
    />
  );
};

export default DashboardWeaknesses;
