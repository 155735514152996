import React, { FC } from "react";
import ReactDOM from "react-dom";
import Icon from "components/Shared/Icons/Icon/Icon";

interface IProps {
  image: string;
  onClose: () => void;
}

const FullscreenImage: FC<IProps> = ({ image, onClose }) => {
  return ReactDOM.createPortal(
    <div className="fullscreen-image-container">
      <div className="modal-content">
        <Icon icon="close" onClick={onClose} className="fullscreen-image-close-button" />
        <img src={`/static/images/${image}`} alt={"fullscreen-image"} />
      </div>
    </div>,
    document.body
  );
};

export default FullscreenImage;
