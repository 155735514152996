import Summary from "components/dashboard/Summary/Summary";
import TodoList from "components/dashboard/TodoList/TodoList";
import DashboardWeaknesses from "components/dashboard/Weaknesses/DashboardWeaknesses/DashboardWeaknesses";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleFetchSummary } from "Store/dashboard/actions";
import { makeSelectSummary } from "Store/dashboard/selectors";
import { makeSelectActiveUser } from "Store/user/selectors";
import WhatsNewTab from "components/dashboard/WhatsNewTab/WhatsNewTab";
import { HeaderContext } from "utils/context/HeaderContext";

const TABS = [
  {
    title: "To Do List",
    key: "todo-list"
  },
  {
    title: "Summary",
    key: "summary"
  },
  {
    title: "Strengths & Weaknesses",
    key: "strengths"
  },
  {
    title: "What's new",
    key: "news"
  }
];

const Dashboard = () => {
  const activeUser = useSelector(makeSelectActiveUser);
  const summary = useSelector(makeSelectSummary);

  const { handleOnChangeHeaderValue, activeTab } = useContext(HeaderContext);

  useEffect(() => {
    handleOnChangeHeaderValue &&
      handleOnChangeHeaderValue({
        headerTabs: TABS,
        isBackButton: false,
        icon: "home",
        title: "Dashboard",
        activeTab: "todo-list",
        additionalInformationContainer: null
      });
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeUser) {
      dispatch(handleFetchSummary());
    }
  }, [activeUser]);

  return (
    <>
      {activeTab === "todo-list" && <TodoList />}
      {activeTab === "summary" && <Summary />}
      {activeTab === "strengths" && summary && <DashboardWeaknesses />}
      {activeTab === "news" && <WhatsNewTab />}
    </>
  );
};

export default Dashboard;
